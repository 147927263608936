<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{counterparty}}</h1>
    <div class="mb-4 clearfix">
      <InstructionButton v-if="!!instructionLink.length" :link="instructionLink" />
      <VBtn v-if="isReviewer || isSpectator" @click="getDownloadLink" class="float-right ml-2" depressed color="default">
        Скачать анкету архивом
      </VBtn>
      <VBtn depressed @click="$router.push({ name: 'pkomain/CounterpartyListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        Назад к списку
      </VBtn>
    </div>
    <div :class="$style.inner">
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" />
      <template v-if="current">
        <VAlert
            v-if="(isReviewer || isSpectator) && current.needFullReview"
            color="primary"
            type="warning"
        >Контрагент проходит повторную предквалификацию. Необходима полная проверка анкеты</VAlert>
        <CounterpartyCard
            v-if="isReviewer || isSpectator"
            label="Предыдущие версии анкеты" class="mb-6"
        >
          <VRow>
            <VCol cols="3">
              <VSelect @change="(value) => this.onVersionChange(value)" :value="version" :items="current.versions" outlined dense hideDetails="auto" />
            </VCol>
          </VRow>
        </CounterpartyCard>
        <CounterpartyInfo :value="current" />
        <CounterpartyCard label="Прогресс согласования" class="mb-6">
          <Stepper v-if="current && current.approval" :items="current.approval" />
        </CounterpartyCard>
        <CounterpartyDetail
            :id="current.id"
            :archive="current.archive"
            :status="current.status.value"
            :values="current.items"
            :comments="commentsInCurrent"
            :version="current.version"
            :pending="pending"
            :can-change-decision="current.canChangeDecision"
        />
        <CounterpartyInfo class="mt-6" :value="current" />
        <div class="mt-6">
          <CounterpartyHistory :items="commentsInCurrent" />
        </div>
        <CounterpartyCard label="История согласования" class="mt-6">
          <ApprovalHistory :items="current.approvalHistory" />
        </CounterpartyCard>
      </template>
    </div>
    <VDialog :value="!!question" max-width="900" scrollable persistent>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="$router.push({ name: 'pkomain/CounterpartyDetailView', params: { counterparty, question: null }})">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5">
          <div v-if="!commentsInDialog.length">Комментарии отсутствуют</div>
          <Comment v-for="comment in commentsInDialog" v-bind="comment" :form="counterparty" :key="comment.id" :readonly="isSpectator" />
          <div v-if="isReviewer && !hasDraftComments && waitDecision" class="pt-5">
            <CommentForm @submit="onSubmitComment" />
          </div>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import {find, get, toNumber} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import {STATUSES} from '@/store/pkomain/enums';
import Comment from '@/components/pkomain/Comment/Comment';
import Stepper from '@/components/pkomain/Stepper/Stepper';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import CounterpartyInfo from '@/components/pkomain/CounterpartyInfo/CounterpartyInfo';
import CounterpartyDetail from '@/components/pkomain/CounterpartyDetail/CounterpartyDetail';
import CounterpartyHistory from '@/components/pkomain/CounterpartyHistory/CounterpartyHistory';
import CounterpartyCard from '@/components/pkomain/CounterpartyCard/CounterpartyCard';
import InstructionButton from '@/components/pkomain/InstructionButton/InstructionButton';
import ApprovalHistory from '@/components/general/ApprovalHistory/ApprovalHistory';
import Api from '@/store/pkomain/api';

export default {
  name: 'CounterpartyDetailView',
  components: {
    Comment,
    Stepper,
    CommentForm,
    CounterpartyInfo,
    CounterpartyDetail,
    CounterpartyHistory,
    CounterpartyCard,
    InstructionButton,
    ApprovalHistory,
  },
  props: {
    counterparty: { type: String },
    question: { type: String },
    version: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'pkomain/pending',
      current: 'pkomain/counterparty',
      commentsInCurrent: 'pkomain/comments',
      hasRole: 'user/hasRole',
      pkoList: 'pko/pkoList',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
    commentsInDialog() {
      return get(this.commentsInCurrent, this.question, []);
    },
    needFetchCounterpaty() {
      return this.version + this.counterparty;
    },
    approvalHistory() {
      return get(this.current, 'approvalHistory', []);
    },
    hasDraftComments() {
      return !!find(this.commentsInDialog, { draft: true });
    },
    waitDecision() {
      return !toNumber(this.version) && get(this, 'current.status.value') === STATUSES.WAIT_FOR_DECISION;
    },
    instructionLink() {
      return get(find(get(this.pkoList, 'items', []), { link: '/pkomain/' }), 'instruction', '');
    },
  },
  methods: {
    ...mapActions({
      fetchCounterpartyDetail: 'pkomain/fetchCounterpartyDetail',
      fetchViewedQuestions: 'pkomain/fetchViewedQuestions',
      fetchCommentList: 'pkomain/fetchCommentList',
      createComment: 'pkomain/createComment',
      fetchPkoList: 'pko/fetchPkoList',
    }),
    onSubmitComment(message) {
      const id = this.counterparty;
      const question = this.question;
      this.createComment({ id, question, message }).then(() => {
        this.fetchCommentList({ id });
      });
    },
    getDownloadLink() {
      const id = this.counterparty;
      Api.downloadFormZip({ id });
    },
    onVersionChange(version) {
      this.$router.push({ name: 'pkomain/CounterpartyDetailView', params: { counterparty: this.counterparty }, query: { version } });
    },
  },
  watch: {
    needFetchCounterpaty: {
      immediate: true,
      handler() {
        if (this.counterparty) {
          this.fetchCounterpartyDetail({ id: this.counterparty, version: this.version });
          this.fetchCommentList({ id: this.counterparty });
          if (this.isReviewer || this.isSpectator) {
            this.fetchViewedQuestions({ id: this.counterparty });
          }
        }
      }
    },
    isReviewer: {
      handler(value) {
        value && this.fetchViewedQuestions({ id: this.counterparty });
      }
    },
    isSpectator: {
      handler(value) {
        value && this.fetchViewedQuestions({ id: this.counterparty });
      }
    },
  },
  mounted() {
    this.fetchPkoList();
  },
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>

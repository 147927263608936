<template>
  <VExpansionPanel>
    <VExpansionPanelHeader class="title">
      <div class="d-flex align-center" :id="code">
        <VIcon v-if="icon" v-text="icon" class="mr-2" />
        <div :class="['title', {[$style.success]: progress.current === progress.total}]">{{ label }}</div>
        <div class="flex-grow-1 pl-4">
          <VTooltip v-if="commentsInCurrentSectionCount['errors']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="error">{{commentsInCurrentSectionCount['errors']}}</VChip>
            </template>
            <span>Замечания</span>
          </VTooltip>
          <VTooltip v-if="commentsInCurrentSectionCount['warnings']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="primary">{{commentsInCurrentSectionCount['warnings']}}</VChip>
            </template>
            <span>Исправления</span>
          </VTooltip>
        </div>
        <div v-if="isReviewer && waitDecision && !!questionsInSectionWithoutComments.length && !pending" class="ml-2">
          <VBtn depressed small color="light" @click.stop="dialog = true">Общий комментарий</VBtn>
        </div>
        <div v-if="mark" class="px-4">
          <VTooltip left>
            <template v-slot:activator="{ on, attrs }">
              <VChip v-bind="attrs" v-on="on" color="info">{{mark}}</VChip>
            </template>
            <span>Оценка</span>
          </VTooltip>
        </div>
        <div v-if="progress" class="px-4">
          <VTooltip left>
            <template v-slot:activator="{ on, attrs }">
              <VChip v-bind="attrs" v-on="on" :color="progress.current === progress.total ? 'success' : 'primary'">{{progress.current}} / {{progress.total}}</VChip>
            </template>
            <span>Заполнено пунктов анкеты: {{progress.current}} из {{progress.total}}</span>
          </VTooltip>
        </div>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent>
      <div v-if="hint" :class="$style.hint" v-html="hint" />
      <template v-if="Object.keys(filtered(sections)).length">
        <VExpansionPanels v-model="panels" flat multiple :key="Object.keys(hiddenKeys).join()">
          <CounterpartyDetailSection
              v-for="(section, code) in filtered(sections)"
              v-bind="{...section}"
              :progress="progressBySection(section)"
              :status="status"
              :readonly="readonly"
              :comments="comments"
              :hiddenKeys="hiddenKeys"
              :key="code"
              :version="version"
          />
        </VExpansionPanels>
      </template>
      <div v-for="(question, code) in filtered(questions)" :class="[$style.item, errorControl(commentControl(code), question.changed)]" :key="code">
        <VRow>
          <VCol cols="12" md="4" :class="!question.label.length ? 'pt-0' : ''">
            <div :class="[$style.title, {[$style.success]: !!~progress.completed.indexOf(question.code)}]" v-html="question.label" />
            <div :class="$style.hint" v-html="question.hint" />
            <div :class="question.label.length ? 'mt-2' : ''">
              <VBtn v-if="(isContragent || isSpectator) && commentControl(code, null).length" :color="commentControl(code).length ? 'primary': 'light'" depressed small @click="toComments(code)">
                <template v-if="commentControl(code).length">
                  {{`Актуальные замечания (${commentControl(code).length})`}}
                </template>
                <template v-else>
                  {{`История замечаний (${commentControl(code, null).length})`}}
                </template>
              </VBtn>
              <template v-if="isReviewer">
                <template v-if="waitDecision">
                  <VBtn v-if="viewed && viewed[code]" :disabled="pending" class="mr-2" depressed color="success" outlined small @click="() => onViewed({code, id})">
                    <VIcon small dark>mdi-eye-check-outline</VIcon>
                  </VBtn>
                  <VBtn v-else :disabled="pending" class="mr-2" depressed color="light" small @click="() => onViewed({code, id})">
                    <VIcon small dark>mdi-eye</VIcon>
                  </VBtn>
                  <VBtn :disabled="pending" depressed small color="light" @click="toComments(code)" >Добавить/удалить замечания</VBtn>
                </template>
                <template v-else-if="commentControl(code, null).length">
                  <VBtn depressed small @click="toComments(code)">
                    {{`История замечаний (${commentControl(code, null).length})`}}
                  </VBtn>
                </template>
              </template>
            </div>
          </VCol>
          <template v-if="['TABLE_CAR', 'TABLE_DRIVER'].includes(question.type) || !question.label">
            <VCol cols="12" class="pt-0">
              <CounterpartyDetailValues v-bind="{...question}" :readonly="question.readonly || readonly" :viewed="viewed" :counterparty-id="id" />
            </VCol>
          </template>
          <template v-else>
            <VCol cols="12" md="8">
              <CounterpartyDetailValues v-bind="{...question}" :readonly="question.readonly || readonly" :viewed="viewed" />
            </VCol>
          </template>
        </VRow>
      </div>
    </VExpansionPanelContent>
    <VDialog v-model="dialog" max-width="900" scrollable persistent>
      <CommentCard :items="[]">
        <template v-slot:top>
          <VToolbar flat dark color="primary">
            <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
            <VSpacer/>
            <VBtn icon dark @click="dialog = false">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </VToolbar>
        </template>
        <template v-slot:bottom>
          <VCardText>
            <div v-if="isReviewer" class="flex-grow-1 px-2 py-4 border-top-1">
              <div class="mb-4">
                <VCheckbox v-for="{code, label, title} in questionsInSectionWithoutComments" v-model="selected" class="mt-0" hide-details :value="code" :label="label || title" :key="code" />
              </div>
              <CommentForm :disabled="!selected.length && pending" @submit="onSubmitComment" />
            </div>
          </VCardText>
        </template>
      </CommentCard>
    </VDialog>
  </VExpansionPanel>
</template>

<script>
import { keys, pick, flatten, filter, map, find, reduce, get, omit, values, merge, toNumber, keyBy, isEmpty, forEach, pickBy, head } from 'lodash-es';
import {mapActions, mapGetters} from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkomain/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import CommentCard from '@/components/general/CommentCard/CommentCard';
import CounterpartyDetailValues from '@/components/pkomain/CounterpartyDetailValues/CounterpartyDetailValues';
export default {
  name: 'CounterpartyDetailSection',
  components: {
    CommentForm,
    CommentCard,
    CounterpartyDetailValues,
  },
  props: {
    code: { type: String },
    mark: { type: Number },
    comments: { type: Object },
    label: { type: String },
    hint: { type: String },
    status: { type: String },
    sections: { type: Object },
    questions: { type: Object },
    readonly: { type: Boolean, default: false },
    pending: { type: Boolean, default: false },
    hiddenKeys: { type: Array, default: () => []},
    version: { type: String, default: '0' },
    progress: { type: Object },
    icon: { type: String }
  },
  data() {
    return {
      dialog: false,
      panels: ~['section_2', 'section_3'].indexOf(this.code) ? [0] : [],
      selected: [],
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      viewed: 'pkomain/counterpartyViewedQuestions',
    }),
    id() {
      return this.$route.params.counterparty;
    },
    commentsInCurrentSection() {
      return pick(this.comments, [...keys(this.questions), ...flatten(map(this.sections, ({questions}) => keys(questions)))]);
    },
    commentsInCurrentSectionCount() {
      return reduce(this.commentsInCurrentSection, (acc, comments, question) => {
        if (this.isContragent) {
          if (find(comments, { actual: true })) {
            acc['errors'] += 1;
            return acc;
          }
        }
        if (this.isReviewer || this.isSpectator) {
          if (find(comments, { draft: true })) {
            acc['errors'] += 1
            return acc;
          }
          if (find(comments, { actual: true })) {
            acc['warnings'] += 1;
            return acc;
          }
          if (get(this.questions, [question, 'changed'], false)) {
            acc['warnings'] += 1;
            return acc;
          }
        }
        return acc;
      }, { errors: 0, warnings: 0 })
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkomain');
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
    waitDecision() {
      return !toNumber(this.version) && this.status === STATUSES.WAIT_FOR_DECISION;
    },
    questionsInSection() {
      return filter(reduce(map(this.filtered(this.sections), 'questions'), merge, this.filtered(this.questions)), ({ label }) => label);
    },
    questionsInSectionWithoutComments() {
      return values(omit(keyBy(this.questionsInSection, 'code'), keys(this.commentsInCurrentSection)));
    }
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'pkomain/fetchCommentList',
      createComment: 'pkomain/createComment',
      setViewedQuestions: 'pkomain/setViewedQuestions',
    }),
    onViewed({ code, id }) {
      this.setViewedQuestions({
        id,
        questions: {
          [code]: !this.viewed[code]
        }
      });
    },
    filtered(items) {
      return omit(items, this.hiddenKeys);
    },
    progressBySection(section) {
      let total = 0;
      let current = 0;
      const sections = this.filtered(section.sections);
      const questions = this.filtered(section.questions);
      const completed = [];
      if (!isEmpty(sections)) forEach(sections, (section) => {
        const progress = this.progressBySection(section);
        total += progress.total || 0;
        current += progress.current || 0;
        completed.push(...progress.completed);
      });
      total += keys(questions).length;
      const temp = keys(pickBy(questions, ({ type, values, rejections }) => {
        // Отказ от ответа
        if (get(rejections, ['BOOL', 0, 'value']) === '1' && get(rejections, ['TEXT', 0, 'value'])) return true;
        // // Не составные вопросы имеют ответ.
        if (get(values, [type, '0', 'value'])) return true;
        // Составные должны быть полностью заполнены
        if (!find(map(values, head), { value: '' })) return true;
        // Составные с BOOL могут быть заполнены как нет
        if (!type.indexOf('BOOL_') && get(values, ['BOOL', 0, 'value']) === '0') return true;
        //  Поля с доп данными валидируются иначе
        const tableKeys = ['carName', 'carNumber', 'carCategory', 'ownType', 'techDateEnd', 'documents', 'driverFio', 'driverLicenseCategory', 'driverLicenseDateEnd', 'driverMedicalCheckupDateEnd'];
        if (!type.indexOf('TABLE_') && !find(map(values, head), ({ additional }) => map(pick(additional, tableKeys), (value) => isEmpty(value) || !value).indexOf(true) + 1)) return true;
        return false;
      }));
      current += temp.length;
      completed.push(...temp);
      return { current, total, completed };
    },
    commentControl(question, predicate = ({ actual, draft }) => draft || actual) {
      if (!predicate) return values(this.commentsInCurrentSection[question]);
      return filter(this.commentsInCurrentSection[question], predicate)
    },
    errorControl(comments, changed) {
      if (this.isContragent && comments.length) return 'error lighten-4';
      if ((this.isReviewer || this.isSpectator) && find(comments, { draft: true})) return 'error lighten-4';
      if ((this.isReviewer || this.isSpectator) && (changed || find(comments, { actual: true}))) return 'orange lighten-4';
    },
    toComments(question) {
      this.$router.push({ name: 'pkomain/CounterpartyDetailView', params: { question }})
    },
    onSubmitComment(message) {
      const id = this.id;
      const question = this.selected;
      this.dialog = false;
      this.createComment({ id, question, message }).then(() => {
        this.fetchCommentList({ id });
      });
    }
  },
  watch: {
    hiddenKeys: {
      immediate: true,
      handler(hiddenKeys) {
        if (this.code === 'section_2') {
          if (!~hiddenKeys.indexOf('section_2_0')) {
            this.panels = [0, 1];
          } else {
            this.panels = [0];
          }
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {

}
.title {
  font-weight: bold;
  font-size: 14px;
}
.success {
  color: #4caf50;
}
.hint {
  font-style: italic;
  font-size: 13px;
}
.item {
  padding: 20px 24px;
  margin: 0 -24px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
</style>
